<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <div class="search-input">
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 80px;">
                                    物料编码：
                                </td>
                                <td width="28%">
                                    <el-input v-model="searchItem.materialCode" style="width: 100%;" size="small"
                                              placeholder="请输入物料编码"
                                    ></el-input>
                                </td>
                                <td style="width: 80px;">
                                    产品名称：
                                </td>
                                <td width="28%">
                                    <el-input v-model="searchItem.materialName" style="width: 100%;" size="small"
                                              placeholder="请输入产品名称"
                                    ></el-input>
                                </td>
                                <td style="width: 80px;">
                                    适用机型：
                                </td>
                                <td width="28%">
                                    <el-select v-model="searchItem.useType" size="small" style="width: 100%"
                                               placeholder="请选择适用机型">
                                        <el-option
                                                v-for="item in useTypeList"
                                                :key="item.wholeCode"
                                                :label="item.name"
                                                :value="item.wholeCode">
                                        </el-option>
                                    </el-select>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding-top: 10px">使用性质：</td>
                                <td width="28%">
                                    <el-select v-model="searchItem.useNature" size="small"
                                               style="width: 100%;padding-top: 10px"
                                               placeholder="请选择使用性质">
                                        <el-option
                                                v-for="item in useNatureList"
                                                :key="item.wholeCode"
                                                :label="item.name"
                                                :value="item.wholeCode">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td width="80px" style="padding-top: 10px">部件：</td>
                                <td width="28%">
                                    <el-select v-model="searchItem.component" size="small"
                                               style="padding-top: 10px;width: 100%"
                                               filterable
                                               placeholder="请选择部件">
                                        <el-option
                                                v-for="item in componentList"
                                                :key="item.wholeCode"
                                                :label="item.name"
                                                :value="item.wholeCode">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td width="80px" style="padding-top: 10px">时间范围：</td>
                                <td width="28%" style="padding-top: 10px">
                                    <el-date-picker
                                            style="width: 100%"
                                            v-model="rangeTime"
                                            type="daterange"
                                            size="small"
                                            align="right"
                                            unlink-panels
                                            value-format="yyyy-MM-dd"
                                            format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            :picker-options="pickerOptions">
                                    </el-date-picker>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colspan="2" style="text-align: center;padding-top: 10px;width: 30%">
                                    <el-button type="primary" icon="el-icon-search" size="small"
                                               style="margin-right: 10%" @click="searchBtu">搜索
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                    </el-button>
                                    <el-button type="primary" icon="el-icon-download" size="small"
                                               style="margin-left: 10%" @click="exportExcel">
                                        导出Excel表格
                                    </el-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                                fit>
                            <el-table-column type="index" fixed label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="materialCode" fixed label="物料编码"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useTypeName" fixed label="适用机型" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="componentName" fixed label="部件" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useNatureName" fixed label="使用性质" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" fixed width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" fixed width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="buyPrice" label="物料总价（元）" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="unitPrice" label="单价（元）" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="unitName" label="单位" width="60"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="safeQuantity" label="安全库存" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="needQuantity" label="需求数量" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quantity" label="库房库存" width="90" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="totalQuantity" label="总库存" width="90" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="totalReceive" label="收料合计" width="90" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="totalGet" label="领料合计" width="90" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="totalOut" label="出库合计" width="90" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="checkQuantity" label="期初库房结存" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="checkTotal" label="期初总结存" width="100" align="center"
                                             show-overflow-tooltip></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                pageList: [14, 20, 50, 100],
                rangeTime: [],
                tableHeight: '',
                fileName: '',
                option: {
                    pageSize: 14,
                    pageIndex: 1,
                },
                listWidth: 145,
                totalPage: 10,
                fields: [],
                values: [],
                lineHeight: '41px',
                searchItem: {
                    materialCode: '',
                    materialName: '',
                    useType: '',
                    useNature: '',
                    component: '',
                    startDate: '',
                    endDate: '',
                    pageIndex: 1,
                    pageSize: 14
                },
                useTypeList: [],
                componentList: [],
                useNatureList: [],
                inputWidth: '',
                tableTitles: [],
                exportHeader: [
                    ['物料编码', '适用机型', '部件', '使用性质',
                        '产品名称', '型号', '单位', '安全库存',
                        '需求数量', '库房库存', '总库存', '收料合计',
                        '领料合计', '出库合计', '期初库房结存', '期初总结存']
                ],
                exportTestData: [
                    'materialCode', 'useTypeName', 'componentName', 'useNatureName', 'materialName', 'model', 'unitName', 'safeQuantity', 'needQuantity', 'quantity',
                    'totalQuantity', 'totalReceive', 'totalGet', 'totalOut', 'checkQuantity', 'checkTotal',
                ],
                tHeader: ["", "", "", "", "", "", "", "", "", "", "", "", "",
                    "", "", ""],
                mergeList: [],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: '最近一个月',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近三个月',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit('pick', [start, end]);
                            }
                        }, {
                            text: '最近六个月',
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                                picker.$emit('pick', [start, end]);
                            }
                        }]
                },
                tableData: [],
                itemList: [],
                materialList: [],
                arr: [],
                currentPage: 1,
            }
        },
        methods: {
            pageChange(option) {
                this.searchItem.pageIndex = option;
                this.option.pageIndex = option;
                this.currentPage = option
                this.$api.getInventoryReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            item.totalQuantity = item.quantity + item.tempQuantity
                            item.checkQuantity = item.checkQuantity == null ? 0 : item.checkQuantity
                            item.needQuantity = item.safeQuantity - item.totalQuantity
                            if (item.inOutDetailList.length>0){
                                for (var itemElement of item.inOutDetailList) {
                                    item.totalReceive = (item.totalReceive || 0) + itemElement.inCount
                                    item.totalGet = (item.totalGet || 0) + itemElement.productCount
                                    item.totalOut = (item.totalOut || 0) + itemElement.outCount
                                }
                            }else {
                                item.totalReceive = 0
                                item.totalGet = 0
                                item.totalOut = 0
                            }
                            item.checkTotal = (item.checkQuantity || 0) + (item.checkTempQuantity || 0)
                            item.buyPrice = (item.buyPrice/100).toFixed(2)
                            item.unitPrice = (item.buyPrice/item.totalQuantity).toFixed(2)
                        }
                        this.materialList = res.data.content[0].inOutDetailList;
                        this.tableData = res.data.content
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.option.pageSize = val;
                this.$api.getInventoryReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            item.totalQuantity = item.quantity + item.tempQuantity
                            item.checkQuantity = item.checkQuantity == null ? 0 : item.checkQuantity
                            item.needQuantity = item.safeQuantity - item.totalQuantity
                            if (item.inOutDetailList.length>0){
                                for (var itemElement of item.inOutDetailList) {
                                    item.totalReceive = (item.totalReceive || 0) + itemElement.inCount
                                    item.totalGet = (item.totalGet || 0) + itemElement.productCount
                                    item.totalOut = (item.totalOut || 0) + itemElement.outCount
                                }
                            }else {
                                item.totalReceive = 0
                                item.totalGet = 0
                                item.totalOut = 0
                            }
                            item.checkTotal = (item.checkQuantity || 0) + (item.checkTempQuantity || 0)
                            item.buyPrice = (item.buyPrice/100).toFixed(2)
                            item.unitPrice = (item.buyPrice/item.totalQuantity).toFixed(2)
                        }
                        this.materialList = res.data.content[0].inOutDetailList;
                        this.tableData = res.data.content
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            searchBtu() {
                var startDate = this.rangeTime[0] + '';
                var endDate = this.rangeTime[1] + '';
                this.searchItem.startDate = startDate.split('T')[0];
                this.searchItem.endDate = endDate.split('T')[0];
                this.pageChange(1);
            },
            reset() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    salesName: '',
                }
                this.initTime()
            },
            exportExcel() {
                for (var i = 1; i < 17; i++) {
                    this.mergeList.push(this.convertToTitle(i) + '1:' + this.convertToTitle(i) + '2')
                }
                this.getExportData();

            },
            formatJson(filterVal, jsonData) {
                return jsonData.map((v) =>
                    filterVal.map((j) => {
                        if (j === "timestamp") {
                            return v[j];
                        } else {
                            return v[j];
                        }
                    })
                );
            },
            getExportData() {
                this.itemList = [];
                this.searchItem.pageSize = 1000;
                this.$api.getInventoryReport(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            item.totalQuantity = item.quantity + item.tempQuantity
                            item.checkQuantity = item.checkQuantity == null ? 0 : item.checkQuantity
                            item.needQuantity = item.safeQuantity - item.totalQuantity
                            if (item.inOutDetailList.length>0){
                                for (var itemElement of item.inOutDetailList) {
                                    item.totalReceive = (item.totalReceive || 0) + itemElement.inCount
                                    item.totalGet = (item.totalGet || 0) + itemElement.productCount
                                    item.totalOut = (item.totalOut || 0) + itemElement.outCount
                                }
                            }else {
                                item.totalReceive = 0
                                item.totalGet = 0
                                item.totalOut = 0
                            }
                            item.checkTotal = (item.checkQuantity || 0) + (item.checkTempQuantity || 0)
                            for (let i = 0; i < item.inOutDetailList.length; i++) {
                                item['inCount' + i] = item.inOutDetailList[i].inCount
                                item['productCount' + i] = item.inOutDetailList[i].productCount
                                item['outCount' + i] = item.inOutDetailList[i].outCount
                            }
                            item.buyPrice = (item.buyPrice/100).toFixed(2)
                            item.unitPrice = (item.buyPrice/item.totalQuantity).toFixed(2)
                            this.itemList.push(item)
                        }
                        this.getSplicingDate();
                        this.getBasicData();
                        const multiHeader = this.exportHeader;
                        const filterVal = this.exportTestData
                        const list = this.itemList; //这个是你循环数据到页面上的的变量名
                        const data = this.formatJson(filterVal, list);
                        const merges = this.mergeList;
                        import("../../utils/Export2Excel").then((excel) => {
                            excel.export_json_to_excel({
                                multiHeader,
                                header: this.tHeader,
                                merges,
                                data,
                                filename: this.fileName,
                                autoWidth: this.autoWidth,
                                bookType: this.bookType,
                            });
                        });
                    }
                })
            },
            getBasicData() {
                var date = new Date();
                var year = date.getFullYear();
                var month = (date.getMonth() + 1) < 10 ? 0 + '' + (date.getMonth() + 1) : ((date.getMonth() + 1))
                var date = date.getDate() < 10 ? 0 + '' + date.getDate() : (date.getDate())
                this.fileName = "库存明细报表" + year + month + date;
            },
            getSplicingDate() {
                this.exportHeader = [
                    ['物料编码', '适用机型', '部件', '使用性质',
                        '产品名称', '型号', '物料总价(元)', '单价(元)', '单位', '安全库存',
                        '需求数量', '库房库存', '总库存', '收料合计',
                        '领料合计', '出库合计', '期初库房结存', '期初总结存']
                ]
                this.exportTestData = [
                    'materialCode', 'useTypeName', 'componentName', 'useNatureName', 'materialName', 'model','buyPrice','unitPrice', 'unitName', 'safeQuantity', 'needQuantity', 'quantity',
                    'totalQuantity', 'totalReceive', 'totalGet', 'totalOut', 'checkQuantity', 'checkTotal',
                ]
                this.tHeader = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "",
                    "", "", ""]
                if (this.materialList.length > 0) {
                    var i = 1;
                    var j = 0;
                    var startDate = new Date(this.materialList[0].date);
                    var endDate = new Date(this.materialList[this.materialList.length - 1].date);
                    while (startDate <= endDate) {
                        this.exportHeader[0].push((startDate.getMonth() + 1) + '.' +
                            (startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()))
                        this.exportHeader[0].push('');
                        this.exportHeader[0].push('');
                        startDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
                        this.tHeader.push('收')
                        this.tHeader.push('领')
                        this.tHeader.push('发')
                        this.exportTestData.push('inCount' + j)
                        this.exportTestData.push('productCount' + j)
                        this.exportTestData.push('outCount' + j)
                        this.mergeList.push(this.convertToTitle(16 + i) + '1:' + this.convertToTitle(16 + 2 + i) + '1')
                        i = i + 3
                        j = j + 1
                    }
                }
            },
            convertToTitle(n) {
                const nums = [];
                let code = 'A'.charCodeAt(0)
                for (let i = code; i < code + 26; i++) {
                    nums.push(String.fromCharCode(i));
                }
                let ans = [];
                let index = 0;
                do {
                    n -= 1;
                    ans.unshift(nums[n % 26]);
                } while (n = Math.floor(n / 26));
                return ans.join('');
            },
            changeDateFormat(data) {
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            initTime() {
                var date = new Date();
                var firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

                this.rangeTime.push(this.changeDateFormat(date))
                this.searchItem.startDate = this.rangeTime.push(this.changeDateFormat(firstDate))[0];
                this.searchItem.endDate = this.rangeTime.push(this.changeDateFormat(firstDate))[1];
                this.pageChange(1)
                this.getDictionary()
            },
            getDictionary() {
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.useTypeList = res.data.useTypeList;
                        this.componentList = res.data.componentList;
                        this.useNatureList = res.data.useNatureList;
                        this.unitList = res.data.unitList;
                    }
                })
            },
        },
        created() {
            this.initTime()
            this.tableHeight = (window.innerHeight) * 0.66
            this.inputWidth = document.body.scrollWidth * 0.25 + 'px';
        }
    }
</script>

<style scoped>
    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 135px;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 10px;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-info {
        height: 87%;
        margin-top: 8px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
        margin-top: 10px;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    td {
        text-align: right;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .search-input {
        display: flex;
    }

</style>